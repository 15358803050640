import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@/react/components/shared/uikit/SanitizeContent";
import { Typography } from "@/react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";
import { Form } from "@circle-react-uikit/Form";
import { Notice } from "@circle-react-uikit/Notice/Notice";
import { FormItem } from "./FormItem";
import { SenderEmail } from "./SenderEmail";
import { SettingsSection } from "./SettingsSection";
import { FIELD_USE_CUSTOM_REPLY_TO_EMAIL, I18N_ROOT } from "./constants";

export const FromAddress = () => {
  const { watch, getValues } = useFormContext();
  const useCustomReplyToEmail = watch(FIELD_USE_CUSTOM_REPLY_TO_EMAIL);
  const [isCustomDomainEnabled] = getValues([
    "is_custom_domain_enabled_for_marketing_hub",
  ]);

  return (
    <SettingsSection
      title={t([I18N_ROOT, "from_address"])}
      description={t([I18N_ROOT, "from_address_description"])}
    >
      <FormItem name="sender_name" label={t([I18N_ROOT, "sender_name"])} />
      <div className="flex flex-col gap-y-6">
        <FormItem
          name="sender_email_local_part"
          label={t([I18N_ROOT, "sender_email_local_part"])}
          tooltipText={
            isCustomDomainEnabled ? "" : t([I18N_ROOT, "sender_email_tooltip"])
          }
        >
          <SenderEmail />
        </FormItem>
        {!isCustomDomainEnabled && (
          <Notice
            iconEl={
              <Icon type="16-info" size={16} className="text-default mt-0.5" />
            }
            className="bg-tertiary border-primary mb-6"
          >
            <Typography.LabelSm>
              <SanitizeHTMLString
                content={t([I18N_ROOT, "from_address_info_notice"])}
                sanitizeProps={{ ADD_ATTR: ["target"] }}
              />
            </Typography.LabelSm>
          </Notice>
        )}
      </div>
      <Form.Item
        className="!flex flex-row-reverse !pt-4"
        labelClassName="text-base text-default !font-normal ml-4"
        translationRoot={I18N_ROOT}
        hideDescription
        hideBorder
        fullWidth
        inline
        name={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
        id={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
      >
        <Form.ToggleSwitch
          id={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
          wrapperClass="!ml-0"
          name={FIELD_USE_CUSTOM_REPLY_TO_EMAIL}
          variant="small"
        />
      </Form.Item>
      {useCustomReplyToEmail && (
        <FormItem
          name="reply_to_email"
          label={t([I18N_ROOT, "reply_to_email"])}
          tooltipText={t([I18N_ROOT, "reply_to_email_tooltip"])}
        />
      )}
    </SettingsSection>
  );
};
